import React, { BaseSyntheticEvent, createContext, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { faBorderAll, faExpand, faPlus, faRulerCombined } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useForceRerender } from "@utils/component";
import { resolveLanguageAssetUrls } from "@utils/language";

import { browserHistory } from "@/ts/router";
import { useProject } from "@providers/project";
import { useVideoPlayer } from "@providers/videoplayer";

import FrameService from "@services/FrameService";
import SubtitleEditorService from "@services/SubtitleEditorService";
import ProjectService from "@services/ProjectService";
import VideoPlayer from "@models/videoplayer";

import Timeline from "@components/timeline/timeline";
import Tooltip from "@components/tippy/tooltip";
import ConfirmModal from "@components/modals/confirm";
import AreaProperties from "./area/properties";
import AreaEffects from "./area/effects";
import AreaHeader from "./area/header";
import ProjectPageKeyBinds from "./keybinds";
import AreaToolbar from "./area/toolbar";
import AreaControls from "./area/controls";
import AreaPlaybackSettings from "./area/settings";
import PreventKeyboardPageScrolls from "@components/misc/prevent_keyboard_page_scrolls";
import GuideWrapper from "@components/guide/guidewrapper";
import { useEventListener } from "usehooks-ts";
import { ControlledMenu, MenuData } from "@components/menu/menu";
import KeyService from "@services/KeyService";

type ZoomProps = {
    moving: boolean,
    type: MovingType,
    offset: Position,
    startingOffset: Position
};

type MovingType = "none" | "videodrag" | "containerdrag";
type Position = {
    x: number,
    y: number
};

const Context = createContext(null);

const ProjectPage = () => {
    const { t } = useTranslation([ "mve", "common" ]);
    const params = useParams()
    const [forceRerender] = useForceRerender();
    const [project, setProject] = useProject();
    const [player, setPlayer] = useVideoPlayer();
    const [videoDimensions, setVideoDimensions] = useState({x: 1, y: 1});

    const videoContainerRef = useRef<HTMLDivElement>();
    const floatingButtonsRef = useRef<HTMLDivElement>();
    const [showRuler, setShowRuler] = useState(true);
    const [showSafeZones, setShowSafeZones] = useState(false);
    const [showProjectLockedWarning, setShowProjectLockedWarning] = useState(false);
    const [showGuideOptions, setShowGuideOptions] = useState(null)
    const [fullscreen, setFullscreen] = useState(false)
    const [zoom, setZoom] = useState(1);
    const [moveZoomed, setMoveZoomed] = useState<ZoomProps>({startingOffset: {x: 0, y: 0}, moving: false, type:"none", offset:{x: 50, y: 50}});
    const [videoPosition, setVideoPosition] = useState<{x: number, y: number}>({x: 50, y: 50});

    const positionContainerRef = useRef<HTMLDivElement>();
    const positionRef = useRef<HTMLDivElement>();

    const isEditingDisabled = project && project.getPrimaryLanguage() !== project.activeLanguage;
    const minimapRatio = 1/5;
    const defaultZoom = 1 / 1.21;

    const loadProject = async () => {
        const project = await ProjectService.load( parseInt(params.projectId) );
        if (project) {
            setProject(project);
        }
    }

    const createPlayer = async () => {
        const videoFile = _.first(project.files.videos);
        if (! videoFile) {
            browserHistory.push(`/project/${project.id}/files`);
            return;
        }

        const player = new VideoPlayer(videoContainerRef.current);
        setPlayer(player);

        player.create();

        await player.loadVideo(videoFile);

        let languageAssetUrls = resolveLanguageAssetUrls(project.languages, project.getPrimaryLanguage());

        player.audioNarration.src = languageAssetUrls?.narration ? project.getServerStoragePath(languageAssetUrls.narration) : null;
        player.audioMusic.src     = languageAssetUrls?.music ? project.getServerStoragePath(languageAssetUrls.music) : null;

        SubtitleEditorService.create(player.subtitleManager.subtitlesHolderElement[0]);

        forceRerender();
        setVideoDimensions({x: videoFile.details.width, y: videoFile.details.height})

        player.subtitleManager.clips = project.getClips();
        player.subtitleManager.createClipElements();

        
        player.updateContainer();

        positionContainerRef.current.style.height = `${pxToFloat($('.video-container').css("height")) * minimapRatio}px`;
        positionContainerRef.current.style.width  = `${pxToFloat($('.video-container').css("width")) * minimapRatio}px`;
        positionRef.current.style.width           = `${pxToFloat($('.area-playback').css("width")) * minimapRatio}px`;
        positionRef.current.style.height          = `${pxToFloat($('.area-playback').css("height")) * minimapRatio}px`;


        return player;
    }

    const createChannel = () => {
        project.createChannel();
        forceRerender();
    }

    const onFrameUpdate = (player: VideoPlayer) => {
        const time = player.currentTime();

        moveCaretToMS(time);
    }

    const moveCaretToMS = (ms: number) => {
        let percentage = ms / player.duration();

        $("#timeline_caret").css("left", `${percentage * 100}%`);
        $("#controls-time").html( player.getTimeString(ms) );

        player.subtitleManager.updateSubtitles();
    }

    const togglePaused = () => {
        let newState = player.togglePaused();

        if (newState) {
            FrameService.cancel("project-page-frame-update");

        } else {
            FrameService.request("project-page-frame-update", onFrameUpdate, [ player ]);
            SubtitleEditorService.setTarget(null);
        }

        forceRerender();
    }

    const toggleSafeZones = () => {
        setShowSafeZones(curr => ! curr);
    }

    const toggleRuler = () => {
        setShowRuler(curr => {
            return !curr});
    }

    const rulerMenuData : MenuData[] = [
        {
            title: "Guide Options",
            header: true
        },
        {
            title: "Delete all guides",
            onClick: () => {
                setShowGuideOptions(null)
                $(".guide-line-container").children().map((_, guide) => {
                    guide.remove()
                })
            }
        }
    ]

    useEventListener("contextmenu", (e: any) => {
        if (e.target.classList.contains("rulerButton") || e.target.id === "ruler"){
            e.preventDefault()
            if (showRuler){
                setShowGuideOptions({x: e.pageX, y: e.pageY})
            }
        }
    })

    // @ts-ignore
    useEventListener("fullscreenchange", () => {
        if (! document.fullscreenElement) {
            setFullscreen(false);
        }
    })

    useEffect(() => {
        if (project) {
            createPlayer();

            if (project.isLocked()) {
                setShowProjectLockedWarning(true);
            }

            updateSize(defaultZoom);
        }

        return () => {
            project?.destructor?.();
        }
    }, [ project ]);

    const pxToFloat = (px: string) => {
        return parseFloat(px.split("px")[0]);
    }


    useEffect(() => {
    
        if (player && positionContainerRef){
            positionContainerRef.current.style.height = `${pxToFloat($('.video-container').css("height")) * minimapRatio}px`;
            positionContainerRef.current.style.width  = `${pxToFloat($('.video-container').css("width")) * minimapRatio}px`;
            positionRef.current.style.width           = `${pxToFloat($('.area-playback').css("width")) * minimapRatio}px`;
            positionRef.current.style.height          = `${pxToFloat($('.area-playback').css("height")) * minimapRatio}px`;
        }

        return () => {
            player?.video?.dispose();

            player?.audioNarration?.pause?.();
            player?.audioNarration?.remove?.();
            player?.audioMusic?.pause?.();
            player?.audioMusic?.remove?.();
        }
    }, [ player ]);

    useEffect(() => {
        loadProject();

        window.projectPageForceRerender = forceRerender;

        return () => {
            FrameService.cancel("project-page-frame-update");
            FrameService.cancel("videoplayer-update-subtitles");

            setProject(null);
            setPlayer(null);
        }
    }, []);

    const updateSize = (zoom = 1) => {
        setZoom(zoom);
        videoContainerRef.current.style.transform = `translate(${-moveZoomed.offset.x}%, ${-moveZoomed.offset.y}%) scale(${zoom})`

        positionRef.current.style.width  = `${pxToFloat($('.area-playback').css("width")) * minimapRatio * (1/zoom)}px`;
        positionRef.current.style.height = `${pxToFloat($('.area-playback').css("height")) * minimapRatio * (1/zoom)}px`;

        videoContainerRef.current.style.transformOrigin = `${moveZoomed.offset.x}% ${moveZoomed.offset.y}%`;

        if (pxToFloat(positionRef.current.style.width) < pxToFloat(positionContainerRef.current.style.width) || pxToFloat(positionRef.current.style.height) < pxToFloat(positionContainerRef.current.style.height)){
            positionContainerRef.current.style.display = "block";
        } else {
            videoContainerRef.current.style.transformOrigin = `50% 50%`;
            setMoveZoomed({moving: false, type: "none", offset:{x: 50, y: 50}, startingOffset:{x:0, y:0}});
            setVideoPosition({x: 50, y: 50});
            positionContainerRef.current.style.display = "none";
            positionRef.current.style.left             = "50%";
            positionRef.current.style.top              = "50%";
            videoContainerRef.current.style.transform  = `translate(-50%,-50%) scale(${zoom})`;
        }
    }

    useEventListener("wheel", (e) => {
        if (KeyService.isPressing("ControlLeft")){
            let tempZoom = zoom;
            if (e.deltaY > 0){
                tempZoom /= 1.1;
            } else {
                tempZoom *= 1.1;
            }
            if (tempZoom < 10 && tempZoom > 0.4){
                updateSize(tempZoom);
                
                player.updateContainer()
            }
        }
    }, videoContainerRef);

    const mouseDownOnPositionContainer = (e) => {
       var clickOffset = {
            x: (positionRef.current.getBoundingClientRect().left + positionRef.current.getBoundingClientRect().width/2) - e.pageX,
            y: (positionRef.current.getBoundingClientRect().top + positionRef.current.getBoundingClientRect().height/2) - e.pageY
        }
        setMoveZoomed(prev => {return{...prev, moving: true, type: "containerdrag", startingOffset: clickOffset}});
    }

    useEventListener("mouseup", (e) => {
        setMoveZoomed(prev => {return {...prev, moving: false, offset:{x: videoPosition.x, y: videoPosition.y}}});
    })

    useEventListener("mousedown", (e : MouseEvent) => {
        if (e.button.valueOf() == 1){
            setMoveZoomed(prev => {return {...prev, moving: true, type: "videodrag", startingOffset:{x: e.pageX, y: e.pageY}}});
        }
    }, videoContainerRef)

    useEventListener("mousemove", (e) => {
        if (moveZoomed.moving){
            const clamp = (num, min, max) => Math.min(Math.max(num, min), max);
            let offset = {x: 50, y: 50};
            if (moveZoomed.type === "containerdrag"){
    
                videoContainerRef.current.style.transformOrigin = `50% 50%`;
    
                offset = {
                    x: clamp(((e.pageX - positionContainerRef.current.getBoundingClientRect().left + moveZoomed.startingOffset.x) / positionContainerRef.current.getBoundingClientRect().width * 100) , 0, 100),
                    y: clamp(((e.pageY - positionContainerRef.current.getBoundingClientRect().top + moveZoomed.startingOffset.y) / positionContainerRef.current.getBoundingClientRect().height * 100), 0, 100)
                }

                positionRef.current.style.left = `${offset.x}%`;
                positionRef.current.style.top  = `${offset.y}%`;
                
                videoContainerRef.current.style.left = "50%";
                videoContainerRef.current.style.top = "50%";

                
            } else if (moveZoomed.type === "videodrag"){
                if (positionContainerRef.current.style.display == "block"){
                    videoContainerRef.current.style.transformOrigin = `50% 50%`;
                    let draggedX = (moveZoomed.startingOffset.x - e.pageX) / videoContainerRef.current.getBoundingClientRect().width * 100;
                    let draggedY = (moveZoomed.startingOffset.y - e.pageY) / videoContainerRef.current.getBoundingClientRect().height * 100;
                    

                    draggedX = clamp(draggedX + moveZoomed.offset.x, 0, 100);
                    draggedY = clamp(draggedY + moveZoomed.offset.y , 0, 100);

                    offset.x = draggedX;
                    offset.y = draggedY;

                    positionRef.current.style.left = `${offset.x}%`;
                    positionRef.current.style.top = `${offset.y}%`;

                }
            }
            setVideoPosition(offset);
            videoContainerRef.current.style.transform = `translate(calc(${(50 - offset.x) * zoom}% - 50%), calc(${(50 - offset.y) * zoom}% - 50%))  scale(${zoom})`;
        }
    })

    useEventListener("resize", () => {
        player.updateContainer();
    })
    

    const contextValue = { moveCaretToMS, togglePaused, videoDimensions, toggleRuler, setFullscreen, zoom, videoPosition, currentTime: () => {return player.currentTime()} };
    return <>
        <PreventKeyboardPageScrolls />

        <div className="page-project">
            {project &&
                <Context.Provider value={contextValue}>
                    <ProjectPageKeyBinds project={project} player={player} />

                    <div className="editor-main">
                        <AreaHeader project={project} />

                        <AreaToolbar project={project} player={player} disabled={isEditingDisabled} />

                        <AreaPlaybackSettings project={project} player={player}>
                            <div ref={floatingButtonsRef} className="floating-btns" style={{zIndex: 10}}>
                                {showGuideOptions && showRuler &&
                                <ControlledMenu
                                    anchorPoint={{x: showGuideOptions.x, y: showGuideOptions.y}}
                                    data={rulerMenuData}
                                    onClose={_ => {setShowGuideOptions(null)}}
                                    opened={true}
                                />
                                }
                                <Tooltip text={ "Ruler (R)" }>

                                    <FontAwesomeIcon className="rulerButton" style={{transform: "rotate(90deg)"}} icon={faRulerCombined} onClick={_ => toggleRuler()} />
                                </Tooltip>

                                <Tooltip text={ t("pages.project.safe_zones") }>
                                    <FontAwesomeIcon icon={faBorderAll} onClick={_ => toggleSafeZones()} />
                                </Tooltip>

                                <Tooltip text={ t("pages.project.fullscreen") + " (F)" }>
                                    <FontAwesomeIcon icon={faExpand} onClick={_ => {
                                        let fs = player?.fullscreen();
                                        setFullscreen(fs);
                                    }} />
                                </Tooltip>
                            </div>
                        </AreaPlaybackSettings>

                        <div className="area-playback" style={{ position: "relative" }}>
                            <div ref={positionContainerRef}
                            className="viewport-position-container"
                            onMouseDown={mouseDownOnPositionContainer}
                            >
                                <div ref={positionRef}
                                className="viewport-position"
                                ></div>
                            </div>

                            <GuideWrapper project={project} hidden={!showRuler || fullscreen }>
                                <div ref={videoContainerRef}>
                                    {showSafeZones && <>
                                        <div className="safe-zone action"></div>
                                        <div className="safe-zone title"></div>
                                    </>}
                                </div>
                            </GuideWrapper>
                        </div>

                        <AreaControls project={project} player={player} />

                        <div className="area-timeline">
                            <div className="mb-2">
                                <span className="title">{ t("pages.project.timeline") }</span>

                                <Tooltip text={ t("pages.project.actions.new_channel") }>
                                    <FontAwesomeIcon icon={faPlus} className="add-btn" onClick={_ => createChannel()} />
                                </Tooltip>
                            </div>

                            {player && <Timeline player={player} />}
                        </div>

                        <AreaProperties project={project} disabled={isEditingDisabled} />

                        <AreaEffects project={project} disabled={isEditingDisabled} />
                    </div>
                </Context.Provider>
            }

            {showProjectLockedWarning &&
                <ConfirmModal
                    title={ t("pages.project.project_locked") }
                    text={ t("pages.project.project_locked_info", { name: project.lockInfo.user.name, date: new Date(project.lockInfo.expire_at * 1000).toLocaleString("hu-HU")}) }
                    showCloseButton={false}
                    confirmText={ t("common:actions.go_back") }
                    cancelText={ t("common:actions.stay") }
                    show={true}
                    hide={() => setShowProjectLockedWarning(false)}
                    callback={({ accepted }) => {
                        if (accepted) {
                            browserHistory.push(`/`);
                        }
                    }}
                />
            }
        </div>
    </>;
}

export default ProjectPage;


export const useProjectPageContext = () => useContext(Context);
